/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/state-in-constructor */
/* eslint-disable array-callback-return */
/* eslint-disable global-require */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { cpf } from 'cpf-cnpj-validator';
import { Container, Button, Form, Row, Alert ,Col, Image} from 'react-bootstrap';
import MaskedInput from "react-maskedinput";
import Topo from './components/topo';
import Rodape from './components/rodape';
import Back from './components/back';




class LoginPaciente extends Component {

      state = {
        nome: '',
        celular: '',
        telefone: '',
        numCpf: '',
        email: '',
        nascimento: '',
      }

  handleChange = (e) => {
    this.setState({
        [e.target.name]: e.target.value
    })
  }

  handleSubmit =(e) => {
    sessionStorage.setItem('@masterclinicapwa/nome',this.state.nome);
    sessionStorage.setItem('@masterclinicapwa/celular',this.state.celular);
    sessionStorage.setItem('@masterclinicapwa/telefone',this.state.telefone);
    sessionStorage.setItem('@masterclinicapwa/cpf',this.state.numCpf);
    sessionStorage.setItem('@masterclinicapwa/nascimento',this.state.nascimento);
    sessionStorage.setItem('@masterclinicapwa/email', this.state.email);


    const cpf0 = this.state.numCpf.replace(/[^\w\s]/gi, '');
    const numCpf = cpf0.replaceAll('_', '');




    if (!this.state.nome) {
      // eslint-disable-next-line no-alert
      alert('Preencha o Nome');
    } else if (!this.state.celular) {
      // eslint-disable-next-line no-alert
      alert('Preencha o Celular');
    } else if (!this.state.nascimento) {
      // eslint-disable-next-line no-alert
      alert('Preencha a Nascimento');
    } else if (!this.state.numCpf) {
      // eslint-disable-next-line no-alert
      alert('Preencha o CPF');
    } else if (
      cpf.isValid(numCpf) === false
    ){
      // eslint-disable-next-line no-alert
      alert("Informe um CPF válido");
    }else {
      this.props.history.push('/especialidades');
    }

    e.preventDefault();
  }

  render() {
    return (
      <div>
        <style type="text/css">
          {`
                .btn{margin-top:10px !important}
                .form-control{margin-bottom:0px !important;}
            `}
        </style>
        <Topo />
        <Container>
          <Row className="mt-2">
            <Col>
              <Back />
            </Col>
            <Col className="text-center titulo mt-2">Dados Pessoais</Col>
            <Col />
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group controlId="nome">
                <Form.Label>Nome Completo</Form.Label>
                <Form.Control
                  name="nome"
                  type="text"
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group controlId="celular">
                <Form.Label>Celular</Form.Label>
                <Form.Control
                  as={MaskedInput}
                  mask="(11)11111-1111"
                  name="celular"
                  type="text"
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group controlId="telefone">
                <Form.Label>Telefone</Form.Label>
                <Form.Control
                  as={MaskedInput}
                  mask="(11)1111-1111"
                  name="telefone"
                  type="text"
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Col>

            <Col xs={12} md={6}>
              <Form onSubmit={this.handleSubmit}>
                <Form.Group controlId="numCpf">
                  <Form.Label>CPF</Form.Label>
                  <Form.Control
                    as={MaskedInput}
                    mask="111.111.111-11"
                    name="numCpf"
                    type="text"
                    onChange={this.handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="nascimento">
                  <Form.Label>Data de Nascimento</Form.Label>
                  <Form.Control
                    as={MaskedInput}
                    mask="11/11/1111"
                    name="nascimento"
                    type="text"
                    onChange={this.handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    name="email"
                    type="email"
                    onChange={this.handleChange}
                  />
                </Form.Group>
                <Button size="lg" block type="submit">
                  Continuar
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
        <Rodape />
      </div>
    );
  };
};

export default LoginPaciente;
