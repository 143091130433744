/* eslint-disable react/state-in-constructor */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable global-require */
import React, { Component } from 'react';
import { Container, Button, Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Topo from './components/topo';
import Rodape from './components/rodape';

// eslint-disable-next-line react/prefer-stateless-function
class InicioPaciente extends Component {

  constructor(props) {
     super(props);
    sessionStorage.setItem('@masterclinicapwa/unidade', '');
  }

  handleClick = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });

      sessionStorage.setItem('@masterclinicapwa/unidade', e.target.value);
       this.props.history.push('/loginpaciente');
  };

  render() {
             const usaLaudo = process.env.REACT_APP_USA_LAUDO;
             const base = process.env.REACT_APP_BASE;
             const unidades = ['SANTO ANDRE', 'SAO BERNARDO', 'MAUA'];
            //  const unidades = ['IGARAPE'];
            //  const unidades = ['Agendamento'];

             return (
               <div>
                 <Topo />
                 <Container>
                   <Row>
                     <Col className="text-center titulo mt-2">
                       Bem vindo, selecione a unidade.
                     </Col>
                   </Row>
                   <style type="text/css">
                     {`
                .btn{margin-top:10px !important}
                .form-control{margin-bottom:0px !important; padding:0px !important}
            `}
                   </style>

                   <Row className="mt-2 bg-white justify-content-md-center">
                     <Col className="somenteDesk p-4">
                       <Image src={'appInicio.jpg'} className="imagem" />
                     </Col>

                     <Col>
                       {unidades.map(unidade => (
                         <Button
                           onClick={this.handleClick}
                           variant="outline-success"
                           value={unidade}
                           size="lg"
                           block
                         >
                           {unidade}
                         </Button>
                       ))}
                       {usaLaudo === '1' && (
                         <Link
                           to={`http://144.217.100.77/consulta_laudos/?cliente=${base}`}
                         >
                           <Button variant="outline-success" size="lg" block>
                             Consultar Laudos
                           </Button>
                         </Link>
                       )}
                     </Col>
                   </Row>
                 </Container>
                 <Rodape />
               </div>
             );
           }
}

export default InicioPaciente;
