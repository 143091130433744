import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Calendario from './Calendario';
import * as serviceWorker from './serviceWorker';
import Especialidades from './Especialidade';
import Profissionais from './Profissionais';
import ConfirmaAgendamento from './ConfirmaAgendamento';
import Convenio from './Convenio';
import Sucesso from './sucesso';
import LoginPaciente from './loginPaciente';
import inicioPaciente from './inicioPaciente';




ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" exact component={inicioPaciente} />
      <Route path="/inicioPaciente" exact component={inicioPaciente} />
      <Route path="/calendario" component={Calendario} />
      <Route path="/especialidades" component={Especialidades} />
      <Route path="/profissionais" component={Profissionais} />
      <Route path="/confirma_agendamento" component={ConfirmaAgendamento} />
      <Route path="/convenio" component={Convenio} />
      <Route path="/sucesso" component={Sucesso} />
      <Route path="/loginPaciente" component={LoginPaciente} />
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);

serviceWorker.register();
